<script>
// This is the same as ArticleTitleDisplayCell.vue, but used on the Articles Grid to show the Edit button
export default {
  name: 'ArticleTitleInfoDisplayCell',
  data: function () {
    return {
      renderValue: '',
      articleData: false,
      buttonVisible: false
    };
  },
  beforeMount() {
    this.renderValue = this.params.value;
  },
  mounted() {
    if (this.params.data) {
      this.articleData = this.params.data

      let isGb = 'NGB';

      if (this.articleData.is_gift_box) {
        isGb = 'GB';
      }

      let isRF = 'NRF';

      if (this.articleData.is_refillable === "RF") {
        isRF = 'RF';
      }

      let content_count = this.articleData.content_count;

      let inner_content_count = this.articleData.inner_content_count;
      let inner_content_unit = this.articleData.inner_content_unit;

      let alcohol_percentage = this.articleData.alcohol_percentage;

      this.renderValue = this.articleData.title + ' / '  +  isGb + ' / ' + isRF  + ' / ' +
          content_count  + ' x ' + inner_content_count + ' ' + inner_content_unit + ' / ' + alcohol_percentage + '%';
    }
  },
  methods: {
  }
};
</script>

<template>
  <div class="articleDisplay">
    <RouterLink :to="{path: '/articles/' + this.articleData.latina_article_id}">
      <div class="articleDisplay__text">
        <span>{{ renderValue }}</span>
        <span class="legend comment" v-if="this.articleData.comments"
              v-b-popover.hover.botton="this.articleData.comments">
        </span>
      </div>
    </RouterLink>
  </div>
</template>

<style lang="scss">
.articleDisplay {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 10px;
    display: inline-block;
  }

  &__button {
    background: transparent;
    font-weight: bold;
    border: 0;
    font-size: 16px;
    height: 27px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #000;
    }

    svg {
      fill: #000;
      width: 16px;
    }

    &:hover {
      i {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }
}
</style>